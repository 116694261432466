import { Item } from '../../type';

export const science: Item = {
  label: 'Экспедиция Виндемской академии',
  count: 4,
  element:
    <>
      <div>Учёные умы из столицы, что прибыли ради астрономических изысканий.</div>
      <div>Столь жадных до знаний людей не остановят ни война, ни потоп.</div>
    </>
}