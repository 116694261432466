import { Item } from '../type';

import s from './battle.module.css';

export const disposition: Item = {
  label: 'Расстановка',
  marker: 'Синий дым и звуковой сигнал',
  element:
    <ol className={s.listContainer}>
      <li>Интенданты отправляются на указанные точки и устанавливают знамена, бойцы соответствующего отряда собираются под знаменем.</li>
      <li>В течении этой фазы игроки вправе укрепить свои позиции безопасным для игроков способом.</li>
      <li>В течении этой фазы можно реализовать дуэли.</li>
    </ol>
}