import { Item } from "../type";

import s from './actions.module.css';

export const darkDeal: Item = {
  label: 'Темная сделка',
  element:
    <>
      <div>Персонаж получает <b>"Метку жертвы"</b> в обмен на быстрое восстановление астрального тела.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Игрок приходит в мертвяк в состоянии <b>"Мертв"</b>.</li>
        <li>Соглашается получить <b>"Метку жертвы"</b>.</li>
        <li>Мастер или интендант записывает игрока в список получивших <b>"Метку жертвы"</b>.</li>
        <li>СТекущее время мертвяка сокращается на <b>1 час</b>.</li>
      </ol>
      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>Посещение мертвяка не единственный способ получить <b>"Метку жертвы"</b>.</li>
        <li>Единственные способы снять <b>"Метку жертвы"</b> это действия <b>"Сожран"</b>, <b>"Прощение"</b> или <b>"Убить персонажа"</b>.</li>
        <li><b>"Метка жертвы"</b> остается у персонажа до тех пор пока не будет снята.</li>
        <li><b>"Метка жертвы"</b> - единственный способ сократить время мертвяка.</li>
      </ol>


    </>
}