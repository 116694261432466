import { Item } from "../type";

import s from './actions.module.css';

export const execution: Item = {
  label: 'Казнь',
  element:
    <>
      <div>Инквизитор казнит тех, кого считает виновным в страшных грехах.</div>
      <div className={s.label}><b>Механика</b></div>
      <ol className={s.listContainer}>
        <li>К жертве применяется действие <b>"Плен"</b> (в данном случае отказаться нельзя).</li>
        <li>Жертва приводится на подготовленное место казни.</li>
        <li>Казнь длится минимум <b>5 минут</b>.</li>
        <li>Сожжение длится минимум 5 минут</li>
        <li>Жертва переходит в состояние <b>"Мертв"</b> (временная смерть).</li>
        <li>Инквизитор забирает у игрока карту <b>"Метка жертвы"</b> и выдает карту <b>"Прощение"</b>.</li>
      </ol>

      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>Персонаж, который был казнен инквизицией, после мертвяка выходит без <b>"Метки жертвы"</b> и психозов.</li>
        <li>В случае если у игрока была <b>"Метка жертвы"</b>, игрок получает карту <b>"Прощение"</b>.</li>
        <li>Если игрок не обладал меткой жертвы, то он ничего не получает.</li>
        <li>Казнь имеет свойство "Полного обыска", но все игровые предметы персонажа уничтожаются.</li>
      </ol>


    </>
}