import { Item } from "../type";

import s from './actions.module.css';

export const cannonPrepare: Item = {
  label: 'Подготовка пушки',
  element:
    <>
      <div>Перед выездом пушка должна быть подготовлена к этому выезду.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>При выезде из лагеря пушка должна сдать фиксированное количество ресурсов (количество необходимых к выезду ресурсов определяется на регистрации орудия, опираясь на внешний вид пушки).</li>
        <li>Орудию выдается 10 снарядов, при этом орудие может докупить до 5 дополнительных снарядов за выезд, т.е. максимальное количество снарядов за выезд – 15 штук.</li>
        <li>Снаряды складываются в зарядный ящик.</li>
        <li>Орудие выкатывается за пределы лагеря.</li>
      </ol>

      <div className={s.label}><i>Дополнительно</i></div>
      <div>При обороне лагеря пушка не имеет ограничения на боезапас и не должна оплачивать «Выезд пушки»</div>
    </>
}