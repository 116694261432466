import { Item } from "../type";

import s from './actions.module.css';

export const tithe: Item = {
  label: 'Церковная десятина',
  element:
    <>
      <div>Официальные представители церкви имеют право затребовать уплаты церковной десятины со всех представителей общества.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Представитель церкви может потребовать раз в день с любого лагеря, города, или локации, определенную сумму денег, равную десятой части дохода (считается самими игроками в рамках отыгрыша).</li>
        <li>В случае уплаты десятины, инквизитор вручает платившему грамоту об уплате десятины, которая вешается на вывеску заведения или хранится у главы локации.</li>
      </ol>

      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>В случае потери грамоты или ее порчи, инквизитор вправе потребовать уплату десятины повторно, ссылаясь на отсутствие документа.</li>
        <li>В случае отказа уплаты десятины, лагерь считается подверженным ереси, что может повлечь за собой потерю королевского контракта.</li>
      </ol>
    </>
}