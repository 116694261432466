import icon from '../../../assets/cards/icons/prisoner.png';

import { Item } from '../../type';

import s from '../items.module.css';

export const prisoner: Item = {
  label: 'Пленник',
  icon: icon,
  element:
    <>
      <div className={s.icon}><img src={icon} alt='' /></div>
      <div>Карта пленник нужна для определения того, что игрок с повязкой <b>"Мертв"</b> может перейти в состояние <b>"Тяжело ранен"</b> внутри лагеря, без посещения мертвяка.</div>
      <div>Выдается интендантом лагеря, в котором данного пленника продали.</div>
      <div>Хранится в ДК игрока, передается только между игроком и интендантом, как доказательство того что игрок может продолжить играть без отсидки мертвяка.</div>
      <div>В случае потери карты считается что игрок умер по дороге.</div>
    </>
}