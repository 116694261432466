import React from "react";

import { CargoVisual } from "../../../components";
import { Item } from "../../type";

export const cargo: Item = {
  label: 'Примеры грузоподъемности',
  element: 
    <>
      <CargoVisual />
    </>
}