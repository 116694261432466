import React from "react";

import { AccordionBlock, ImagesAdaptive, ItemModal, LinkButton } from "../../../components";
import { BuildTable } from "./build-table";
import { captain, quartermaster } from "../../../rules-text/examples/characters";
import { Item } from "../../../rules-text/type";

import build_01 from '../../../assets/buildings/build_01.jpg';
import build_02 from '../../../assets/buildings/build_02.jpg';
import build_03 from '../../../assets/buildings/build_03.jpg';
import build_04 from '../../../assets/buildings/build_04.jpg';
import build_05 from '../../../assets/buildings/build_05.jpg';
import build_06 from '../../../assets/buildings/build_06.jpg';
import build_07 from '../../../assets/buildings/build_07.jpg';
import build_08 from '../../../assets/buildings/build_08.jpg';
import build_09 from '../../../assets/buildings/build_09.jpg';
import build_10 from '../../../assets/buildings/build_10.jpg';

import wall_01 from '../../../assets/buildings/wall_01.jpg';
import wall_02 from '../../../assets/buildings/wall_02.jpg';
import wall_03 from '../../../assets/buildings/wall_03.jpg';

import gate_lvl01 from '../../../assets/buildings/gate_lvl01.jpg';
import gate_lvl02 from '../../../assets/buildings/gate_lvl02.jpg';
import gate_lvl03 from '../../../assets/buildings/gate_lvl03.jpg';

import s from './camp.module.css';

const socialBuildings = [build_01, build_02, build_03, build_04, build_05, build_06, build_07, build_08, build_09, build_10];
const warBuildings = [wall_01, wall_02, wall_03];
const gateBuildings = [gate_lvl01, gate_lvl02, gate_lvl03];

export function Location(): JSX.Element {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [item, setItem] = React.useState<Item | null>(null);

  const handleClick = React.useCallback((item: Item) => {
    setItem(item);
    setIsOpen(true);
  }, []);

  return (
    <>
      <AccordionBlock
        label='Постройки'
        items={[
          {
            label: 'Уровень постройки',
            element: 
              <>
                <br />
                <div>Каждой локации будет присвоен уровень от 1 до 5, согласно следующим параметрам:</div>
                <ol className={s.listContainer}>
                  <li><b>Антуражность.</b> Количество и качество антуража инструментов и рабочих мест.</li>
                  <li><b>Масштаб. </b> Площадь которую занимает постройка</li>
                  <li><b>Помощники. </b> Сколько людей участвует в производстве</li>
                </ol>
                <br />
                <div><i>Дополнительно</i></div>
                <ol className={s.listContainer}>
                  <li>В течении игры уровень постройки может меняться, если изменяются вышеперечисленные параметры.</li>
                  <li>Уровень постройки обозначается на вывеске локации.</li>
                  <li>Уровень постройки может меняться только мастерами.</li>
                </ol>
              </>
          },
          {
            label: 'Экономические строения',
            element: 
              <>
                <div>В лагере наемников могут стоять только <b>"Кузница"</b> и <b>"Госпиталь"</b>, остальные строения могут располагаться только в черте города.</div>
                <BuildTable />
              </>
          },
          {
            label: 'Увеселительные заведения',
            element:
              <>
                <div>Это локации, которые не производят товары, но могут предоставлять различные услуги отдыха., к примеру, бордели, чайные домики, массажные, таверны, цирки и т.д.</div>
                <div><i>Общее</i></div>
                <ol>
                  <li>Главой постройки для отдыха является игрок с ролью <b>"Хозяин"</b> - через него проходят все игровые взаимодействия игроков с локацией.</li>
                  <li>Если хозяин отсутствует, то локация считается не доступной для предоставления отдыха, даже если внутри есть свободные работники.</li>
                  <li>Фиксацией предоставленной услуги является карта <b>"Отдых"</b>, переданная хозяином игроку.</li>
                  <li>На время отдыха игрок должен пробыть в локации или возле нее не менее <b>15 минут</b>.</li>
                  <li>Цена за отдых определяется исключительно хозяином заведения.</li>
                  <li>Если вы хотите, чтоб игроки, заходя в ваше заведение сдавали оружие или снимали шлемы, то позаботьтесь о наличии соответствующих стоек для хранения амуниции.</li>
                  <li>Увеселительные заведения могут располагаться только в городе.</li>
                </ol>

                <div><i>Дополнительно</i></div>
                <ol>
                  <li>Карты отдыха игрокам выдаются только из <b>"Колоды Хозяина"</b>.</li>
                  <li>Если в колоде нет карт, то считается, что качество услуг настолько низкое что персонаж в нем не отдыхает вовсе.</li>
                </ol>
              </>
          },
          {
            label: 'Коммерческие постройки',
            element:
              <>
                <div>Если игрок вывозит коммерческие постройки которые намереваются предоставлять игрокам реальные товары, то ознакомьтесь с требованиями:</div>
                <div className={s.listContainer}>
                  <li><b>Обмен реальным деньгами запрещен:</b> используйте внутренние монеты, абонементы или другие способы расчета вне рамок мероприятия.</li>
                  <li><b>Надежность:</b> качество товара или услуги не должны портить игрокам впечатление о мероприятии.</li>
                  <li><b>Ответственность:</b> за качество товаров и услуг отвечают исключительно хозяин коммерческой постройки и потребитель.</li>
                  <li><b>Не игровое:</b> распространять товары или услуги с целью нарушить игровой баланс строжайше запрещается.</li>
                  <li><b>Покупатель всегда прав:</b> в любой непонятной ситуации вы должны исходить из этого постулата.</li>
                  <li><b>Игровые деньги не причем:</b> продажа за игровые деньги возможна, но мастера вам ничего не обязаны возмещать.</li>
                </div>
                <div>Все эти условия нужны чтобы защитить игроков от не качественных товаров.</div>
              </>
          },
          {
            label: 'Ворота города',
            element:
              <>
                <div>Воротам города в зависимости от их антуражность присваивается определенное количество очков прочности.</div>
                <div className={s.lavel}>Технические требования к воротам города:</div>
                <div className={s.listContainer}>
                  <li>Общая ширина укреплений минимум 9 метров.</li>
                  <li>Внешняя часть ворот и стен должна быть выполнена из твердых материалов, имитирующих укрепления (каменная кладка, частокол и т.д.).</li>
                  <li>Во внешней части укрепления должны отсутствовать торчащие крепежные элементы (гвозди, саморезов).</li>
                  <li>Все укрепления должны крепко держаться на земле и не «шататься»</li>
                  <li>Проем ворот должен иметь минимальный размер 2.5м*2.5м.</li>
                  <li>Допускаются только двухстворчатые ворота.</li>
                  <li>В проеме ворот должны отсутствовать предметы или конструкции, мешающие проходу.</li>
                  <li>Ворота должны быть оборудованы запирающим механизмом, который не даст их открыть легким толчком.</li>
                  <li>Ворота должны иметь механизмы фиксации створок ворот в открытом состоянии.</li>
                  <li>Ворота и стены должны уверено выдерживать попадания теннисного мяча из ролевой пушки.</li>
                  <li>Концепт и размеры крепости должны обсуждать с мастерами еще на фазе проектирования.</li>
                </div>

                <ImagesAdaptive
                  images={gateBuildings}
                  isNoAdaptive
                />
              </>
          },
          ]
        }
      />

      <AccordionBlock
        label='Локация'
        items={[
          {
            label: 'Лагерь',
            element:
              <>
                <div>Это отдельная автономная локация, где расположили бойцы наемного отряда, готовые выполнять свои боевые задачи.</div>
                <div className={s.label}><i>Требования к обороне лагеря</i></div>
                  <ol className={s.listContainer}>
                    <li>Если лагерь предполагает оборону на своей территории, то должна быть расчищена площадка для битвы внутри лагеря, на этой зоне должно быть никаких припятский.</li>
                    <li>В любой лагерь должен быть размечен вход минимум 2.5 м в ширину.</li>
                    <li>Весь игровой периметр лагеря должен быть огорожен минимум веревкой на высоте не менее метра.</li>
                    <li>При установке оборонительных сооружений постарайтесь избегать острых краев, торчащих саморезов, всего что может нанести травму игроку в активной фазе боя.</li>
                    <li>Сооружения, предполагающие наличие игрока над землей (башня) и участвующие в активной фазе боя не должны иметь игроков на первом этаже.</li>
                    <li>Использовать в обороне растяжки, и ловушки способные нанести травму игроку строжайше запрещено.</li>
                    <li>Рыть реальные рвы запрещено.</li>
                    <li>Сдвигать, ронять, ломать чужие оборонительные заграждения строжайше запрещено.</li>
                    <li>В случае попадания ядра от пушки в любой элемент обороны, он должен быть немедленно выведен из игры (попали в ворота - открыть ворота, в мантелет - положить мантелет, в башню, спустить всех игроков с башни и т.д.).</li>
                    <li>Все защитные элементы должны быть рассчитаны так что через них могут стрелять и воевать, если в ходе боя элемент обороны развалился, его надо немедленно убрать чтоб избежать травм. Только в этом случае применяется мастерский стоп.</li>
                    <li>Штурмовая зона лагеря должна быть размечена яркими флажками и меть длину не менее 10 метров (включая длину проема ворот).</li>
                    <li>Точка где должны строиться штурмующие обозначается мастерами и там устанавливается специальный знак.</li>
                    <li>Время необходимое для установки лагеря не должно превышать 24 часа.</li>
                  </ol>
                  <div className={s.label}><i>Обязательный командный состав</i></div>
                  <div><b><LinkButton text='Капитан' onclick={() => handleClick(captain)} /></b> Отвечает за игровые действия команды (полноценный игрок)</div>
                  <div><b><LinkButton text='Интендант' onclick={() => handleClick(quartermaster)} /></b> Отвечает за контроль игровых действий команды (регионал из состава команды)</div>

                  <div className={s.label}><i>Экономические строения</i></div>
                  <div>На территории лагеря наемников можно установить только <b>"Кузницу"</b> и <b>"Госпиталь"</b>.</div>
              </>
          },
          {
            label: 'Город',
            element:
              <>
                <div>Город на старте игры принадлежит Мидленду, но вся верхушка города сбежала после слухов что приближаются силы Тюдор. Город брошен на произвол судьбы.</div>
                <div>Игровая охрана города осуществляется за счет стражи, которая состоит из игроков</div>
                <br />
                <div className={s.label}><i>В городе располагаются</i></div>
                <ol className={s.listContainer}>
                  <li>Экономические постройки.</li>
                  <li>Увеселительные заведения.</li>
                  <li>Коммерческие постройки.</li>
                  <li>Мастерка.</li>
                  <li>Лагерь инквизиции.</li>
                </ol>
                <br />
                <div className={s.label}><i>Дополнительно</i></div>
                <ol className={s.listContainer}>
                  <li>Житель города на старте получает деньги за: шатер, жилое место или рабочее место, согласно уровню его антуража.</li>
                  <li>Стража города осуществляется за счет игроков.</li>
                  <li>Игровыми действия в городе заведуют: <b>"Староста"</b> и <b>"Мастерский торговец"</b>.</li>
                </ol>
              </>
          },
          {
            label: 'Логово',
            element:
              <>
              <div>Место где обитает апостол со своими слугами, это может быть шатер, пещера, старый коллектор, кладбище. Место где апостол может чувствовать себя в безопасности.</div>
              <div className={s.label}><i>Требования</i></div>
                <div className={s.listContainer}>
                  <li>Гнетущая атмосфера - даже днем логово должно быть мрачным, загадочным и зловещим.</li>
                  <li>Логово апостола - скрытое место где можно сменить свою форму.</li>
                  <li>Сокровищница - место для хранения всех отобранных ресурсов. Небольшой сундук/ларец/яма, где особо рискованные игрок могли бы получить награду за любопытство.</li>
                  <li>Темница - место для хранения карт "Черепов" - использовать, которые может только чудовище. Воровать и уничтожать черепа любым другим игрокам запрещено.</li>
                  <li>Сокровищница должна располагаться на территории «Арены» на которой чудовище может дать бой игрокам.</li>
                </div>
                <br />
                <div className={s.label}><i>Ограничения</i></div>
                <div className={s.listContainer}>
                  <li>Не могут иметь на территории экономических построек.</li>
                  <li>Место где чудовище переодевается – не игровая зона.</li>
                </div>
              </>
          },
          {
            label: 'Подземелье',
            element:
              <>
                <div>Особое место, которое испытывает навыки игроков, и где работают особые правила.</div>
                <div className={s.label}><i>Требования</i></div>
                <ol className={s.listContainer}>
                  <li>Оригинальность – чем интересней, тем лучше.</li>
                  <li>Минимальное количество условностей, если есть сомнения в определенных идеях следует обсудить их с хронистами.</li>
                  <li>Для каждого подземелья требуется наличие своего хранитель подземелья, через которого будут контролироваться все игромеханические процессы.</li>
                  <li>Хранитель подземелья встречает игроков и по окончанию путешествия провожает их.</li>
                  <li>Посещение подземелья без хранителя подземелья запрещено.</li>
                </ol>
                <br />
                <div className={s.label}><i>Дополнительно</i></div>
                <ol className={s.listContainer}>
                  <li>Для каждого подземелья будет сформирована <b>"колода подземелья"</b> в которую входят психозы и ресурсы.</li>
                  <li>Соотношение психозов и ресурсов, как и количество карт в колоде определяется качеством подготовки самого подземелья при его сертификации.</li>
                  <li>Если карты в колоде подземелья закончились - подземелье не работает.</li>
                  <li>Чтоб пополнить карты колоды подземелье должно изменить внутреннюю конфигурацию или общий смысл прохождения и пройти новую сертификацию.</li>
                  <li>В каком количестве и какие карты выдавать игрокам после прохождения подземелья остается на усмотрение хранителя подземелья.</li>
                </ol>
              </>
            },
        ]}
      />

      <AccordionBlock
        label='Примеры строений'
        items={[
          {
            label: 'Введение',
            element:
              <>
              <div className={s.listContainer}> 
                <div>Ниже приведенные примеры являются гарантией допуска и максимальной антуражность</div>
                <div>Если у вас есть планы вывозить нечто отлично епо конструкции с данными образцами - это следует оговорить с Хронистами</div>
                <div>Основное правило 5 метров: ваше здание/затер с расстояния 5 метров должны выглядеть как исторические аналоги</div>
                <div>Если вы не планируете "играть" внутри данного строения, то внутри вы можете располагать все что угодно, но снаружи здания не должно быть не антуражных предметов</div>
                <div>Внутри шатров и в непосредственной близости запрещается разводить костры или небезопасное пламя</div>
                <div>Все растяжки должны быть помечены белой лентой, чтоб избежать травматичности ночью</div>
                <div>Вход в лагерь/город должен быть ночью подсвечен</div>
                <div>Все торчащие элементы укреплений в сторону игроков должны быть тупыми и помеченным белой тряпкой или краской</div>
              </div>
              </>
          },
          {
            label: 'Жилые строения',
            element:
              <>
                <div>Эти строения можно возводить в одиночку и быстро</div>
                <div>Они пригодны как для жилья так и для локации</div>
                <br />
                <ImagesAdaptive
                  images={socialBuildings}
                  isNoAdaptive
                />
              </>
          },
          {
            label: 'Укрепления лагеря',
            element:
              <>
                <div>Данные сооружения достаточно легко возводятся и перемещаются</div>
                <div>Во время финальной битвы можно использовать свои защитные сооружения лагеря</div>
                <br />
                <ImagesAdaptive
                  images={warBuildings}
                  isNoAdaptive
                />
              </>
          }
        ]}
      />

      {isOpen && (
        <ItemModal
          setIsOpen={setIsOpen}
          item={item}
        />
      )}
    </>
  )
}