import { Item } from "../type";

import s from './actions.module.css';

export const search: Item = {
  label: 'Обыск лагеря',
  element:
    <>
      <div>После удачного штурма атакующая сторона может осмотреть лагерь под присмотром интенданта.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>После победы штурмующие отходят назад за область входа в лагерь.</li>
        <li>К штурмующим выходит делегация побежденных и интендант.</li>
        <li>Коллегиально принимается решение о том, сколько сыщиков будет допущено внутрь для обыска лагеря, будет ли сдача ценностей добровольной.</li>
        <li>Атакующие проводят аккуратный осмотр лагеря, в котором могут находится игровые ресурсы, деньги или предметы.</li>
        <li>Если обороняющаяся сторона не хочет, чтоб по их лагерю ходили чужаки то может отдать все самостоятельно.</li>
        <li>Сыщики не должны ломать или портить предметы. Для правомерности проведения обыск сопровождает интендант.</li>
        <li>Количество сыщиков определяет интендант.</li>
        <li>Во время обыска никто посторонний в лагерь не может проникнуть.</li>
        <li>Время и формат обыска определяется интендантом.</li>
      </ol>
    </>
}