import { Item } from "../type";

import s from './actions.module.css';

export const salary: Item = {
  label: 'Жалованье',
  element:
    <>
      <div>Королевский контракт вашего отряда обеспечен деньгами короны.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>В начале цикла интендант осматривает лагерь.</li>
        <li>Согласно текущему состоянию лагеря интендант выдает жалованье капитану.</li>
      </ol>

      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>Если лагерь был лишен королевского контракта, то жалованье перестает выдаваться.</li>
        <li>Общие требования, на которые будет смотреть интендант:</li>
        <ul className={s.listContainer}>
          <li>Отсутствие пластика или искусственных тканей/материалов.</li>
          <li>Бытовые строения (оружейные стойки, столы скамейки, полки, сундуки).</li>
          <li>Чистота в лагере.</li>
          <li>Укрепления.</li>
          <li>Техника безопасности.</li>
        </ul>
        <li>Основным параметром для определения лагеря будет служить количество и качество шатров в лагере.</li>
      </ol>
    </>
}