import { Item } from "../type";

import s from './actions.module.css';

export const pray: Item = {
  label: 'Молитва',
  element:
    <>
      <div>Инквизитор проводит молебен во славу Святого престола.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Участвующие в молитве должны встать на колено для того чтоб молитва подействовала на них.</li>
        <li>Инквизитор читает молитву не менее <b>10 минут</b>.</li>
        <li>Молитва заканчивается словом "Аминь".</li>
        <li>Эффект молитвы применяется на всех игроков, которые в ней участвуют.</li>
      </ol>

      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>Всего в молитве могут участвовать до 5 игроков помимо инквизиции.</li>
        <li>Если игрок отказывается от участия в молитве и был при этом убит, то сожжение его трупа снимет метку жертвы и даст карту <b>"Прощение"</b>.</li>
        <li>Количество молитв за день определяется количеством карт <b>"Прощения"</b> (оглашается инквизитору в начале цикла (новый игровой цикл начинается в 10:00 и в 15:00).</li>
        <li>Если молитва была прервана, то эффект от нее не накладывается, при этом возможность использования молитвы инквизиторами считается состоявшейся.</li>
      </ol>

      <div className={s.label}><i>Действие молитвы на игрока с «Меткой жертвы» и на чудовищ</i></div>
      <ol className={s.listContainer}>
        <li>Молящийся с меткой жертвы переходит в состояние <b>"Тяжело ранен"</b>.</li>
        <li>Персонаж-чудовище в человеческой форме переходит в состояние "Мертв" (идет в логово с повязкой <b>"Мертв"</b> для превращения в монструозную форму).</li>
      </ol>

    </>
}