import { Item } from "../../../../rules-text/type";
import { AccordionBlock } from "../../../accordion/accordion-block";

import s from './text.module.css';

export const campBuild: Item = {
  label: 'Строяк',
  element:
    <>
      <div className={s.container}>
        <div>Строяк дело важное, и тот кто умеет им заниматься - тот король полигона</div>
      </div>

      <AccordionBlock
        label="Требования"
        items={[
          {
            label: 'Безопасность',
            element:
              <>
                <div>Все элементы строяк должны быть безопасны, не должно быть торчащих гвоздей, растянутых веревок на уровне глаз, вырытых глубоких ям</div>
              </>
          },
          {
            label: 'Остатки',
            element:
              <>
                <div>Все остатки от строяка можно постелить на землю обозначив таким образом тропинки, чтоб они не размывались от грязи</div>
                <div>Все остатки нужно складировать таким образом чтоб они не мешали игровому процессу и не бросались в глаза</div>
                <div>После игры весь строяк который вы хотите оставить на полигоне нужно сложить в указное организаторами место</div>
              </>
          },
          {
            label: 'Расходники',
            element:
              <>
                <div>Старайтесь не раскидываться саморезами и гвоздями вокруг, складывайте их в мусор, так как случайный саморез или гвоздь может пробить подошву или проколоть шину</div>
              </>
          },
          {
            label: 'Время',
            element:
              <>
                <div>На строяк выделяется строго 1 сутки, рассчитывайте на это время</div>
              </>
          },
        ]}
      />

      <AccordionBlock
        label="Помощь"
        items={[
          {
            label: 'Планирование',
            element:
              <>
                <div>Все планы по строяку сложнее мебели должны быть согласованы с организаторами</div>
                <div>Для более полного планирования рекомендуем воспользоваться сайтом</div>
                <div>Для более полного планирования рекомендуем воспользоваться сайтом <a href='http://larpinfo.ru/' target='_blank' rel="noreferrer" style={{color: 'goldenrod'}}>Калькулятор строяка</a></div>
              </>
          },
          {
            label: 'Организация',
            element:
              <>
                <div>Распределите все работы между членами команд и знайте кто за что отвечает, это позволит сэкономить вам время и нервы на самом мероприятии</div>
              </>
          },
        ]}
      />
    </>
}