import { Item } from "../rules-text/type";

import kushanCoat from '../assets/coats/kushan.png'; 

import s from './history.module.css';

export const kushanHistory: Item = {
  label: 'Империя Кушан',
  element:
    <>
      <div className={s.info}>
        <div className={s.coat}>
          <img src={kushanCoat} alt='' />
        </div>

        <div className={s.text}>
          <div><i>Правитель: </i>император Раджендра</div>
          <div><i>Столица: </i>город Великий Дворец</div>
          <div><i>Герб: </i>Длань со всевидящим оком на фиолетовом фоне</div>
          <div><i>Цвета: </i>Фиолетовый, черный и белый, золотой</div>
        </div>
      </div>

    <div className={s.block2}>
      <h3>Краткая история</h3>
      <div>Долгое время империя Кушан была нейтральной и находилась на отдельном берегу от континента</div>
      <div>Но с приходом императора Раджендры было принято объявление войны герцогству Валлатория по причине закрытых портов для торговли товарами</div>
      <div>В битве за "Темное море" флот Кушан на голову разбил флот Валлатории, и смог высадится</div>
      <div>Легкая кавалерия герцогства не смогла противостоять тяжелым слонам и отрядам черных магов, что позволило быстро и эффективно кушанам захватить береговую линию герцогства</div>
      <div>После заключения мира, столица империи была перенесена в Великий Дворец, который распологался на месте стратегического пролива</div>
      <div>На текущий момент империя собирает силы очевидно не для обороны своих границ, а для дальнейшей экспансии</div>
      <div>На стороне Кушан воюют черные маги и тайне ордена убийц, каждое из этих сословий покрыто тайной</div>
      <br />
      <div>Кушанские дипломаты и торговый флот активно пытаются провести и религиозную экспансию на земли континента, чем противостоит Альянс Панерия и Святой Престол</div>
    </div>
  </>
}