import { NavLink } from "react-router-dom";
import { Item } from "../../../../rules-text/type";
import { AccordionBlock } from "../../../accordion/accordion-block";

import s from './text.module.css';

export const economic: Item = {
  label: "Экономика",
  element:
    <>
      <div className={s.container}>
        <div>Вся экономика строится на производстве ремонтных наборов, аптечек и зелий в мастерских</div>
        <div>
          <NavLink replace to='/help/build-calc' onClick={() => document.body.style.overflowY = 'visible'} style={{color: 'goldenrod'}}>Расчет эффективности мастерской/госпиталя</NavLink>
        </div>
      </div>

      <AccordionBlock
        label="Основы"
        items={[
          {
            label: 'Время',
            element:
              <>
                <div>В основу всех экономических действия поставлено время и отыгрыш</div>
                <div>При этом жесткого контроля со стороны организаторов не будет, каждый мастер в своей мастерской - сам себе хозяин и мы верим то что требования отыгрыша и времени будет исполнены</div>
              </>
          },
          {
            label: 'Грузоподъемность',
            element:
              <>
                <div>Количество переносимого груза на игрока строго ограничено, это вызвано желанием создать необходимость в торговцах и различных телегах, тачках и носилках</div>
                <div>
                  <NavLink replace to='/rules/resources' onClick={() => document.body.style.overflowY = 'visible'} style={{color: 'goldenrod'}}>Подробнее о грузоподъемности и экономике</NavLink>
                </div>
              </>
          },
          {
            label: 'Цены',
            element:
              <>
                <div>Цены на услуги и товары выставляют сами мастерские и торговцы исходя из своих соображений, никто не будет спускать "сверху" базовые ценники</div>
                <div>Торговаться с можно и нужно, это неотъемлемый элемент отыгрыша</div>
              </>
          },
        ]}
      />

      <AccordionBlock
        label="Модель"
        items={[
          {
            label: 'Производство',
            element:
              <>
                <div>Любые расходники можно купить у мастерского торговца, но учитывайте что они всегда будут завышены, хоть и можно будет попробовать сторговаться</div>
              </>
          },
          {
            label: 'Личное отношение',
            element:
              <>
                <div>В качестве мастера всегда следует лично оценивать покупателя, пришел ли он с уважение, как одет, манеры и так далее</div>
                <div>В качестве игрока старайтесь не обижать мастеров так как он может легко лично для вас завысить цены или даже отказаться предоставлять свои услуги вам, что может осложнить игру для вас дальше</div>
                <div>Строжайше запрещается использовать информацию после смерти, это тяжело, но постарайтесь обойти этот блок</div>
              </>
          },
          {
            label: 'Записи',
            element:
              <>
                <div>Не стоит отметать такой элемент торговли как бюрократия - записывайте кому и сколько чего вы продали, как продали, это поможет вашим будущим персонажам в случае потери вашего быстрее разобраться что к чему</div>
              </>
          },
        ]}
      />
    </>
}