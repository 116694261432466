import { Item } from "../type";

import s from './actions.module.css';

export const ransom: Item = {
  label: 'Награда за пленника',
  element:
    <>
      <div>Пленного персонажа можно продать в своем лагере интенданту, получив за него награду.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Интендант и пленник выясняют максимальную сумму хитов у персонажа.</li>
        <li>Интендант передает указанное количество средств.</li>
        <li>Интендант выдает пленнику карту <b>"Пленник"</b>.</li>
        <li>Пленник должен в состоянии <b>"Мертв"</b> добраться до своего родного лагеря и вернуться в игру тем же персонажем.</li>
      </ol>

      <div className={s.label}><i>Возвращение пленника в лагерь</i></div>
      <ol className={s.listContainer}>
        <li>Пленник возвращается в свой родной лагерь кратчайшим путем в состоянии <b>"Мертв"</b>.</li>
        <li>Пленник передает интенданту своего лагеря карту <b>"Пленник"</b>.</li>
        <li>Пленник переходит в состояние <b>"Тяжело ранен"</b>.</li>
        <li>Персонаж остается тем же.</li>
      </ol>
      
      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>Награду за пленника могут получать только представители противоборствующих лагерей, т.е. Мидленд за пленника из Тюдор, и Тюдор за пленника из Мидленда.</li>
      </ol>
    </>
}