import { Item } from "../type";

import s from './actions.module.css';

export const heal: Item = {
  label: 'Лечение',
  element:
    <>
      <div className={s.label}>Лечение в госпитале</div>
      <div>Персонажу оказывается профессиональная помощь в госпитале.</div>

      <ol className={s.listContainer}>
        <li>Игрок переходит в статус <b>"Лечение"</b> (одевает красную повязку с зеленой печатью).</li>
        <li>Раненый игрок должен пробыть <b>15 минут</b> возле госпиталя.</li>
        <li>Игроку восстанавливаются все живые хиты.</li>
        <li>Игрок выходит из статуса <b>"Лечение"</b> (снимает повязку).</li>
      </ol>
      <div>Примечание: Никакие ресурсы при этом не расходуются. Количество одновременно лечащихся ограничено количеством лежанок.</div>

      <div className={s.label}>Лечение <b>"Медкомплектом"</b></div>
      <div>Персонаж спасается от смерти благодаря заготовленному комплекту для оказания медицинской помощи.</div>

      <ol className={s.listContainer}>
        <li>Карта <b>"Медкомплект"</b> разрывается.</li>
        <li>Игрок переходит в статус <b>"Лечение"</b> (одевает красную повязку с зеленой печатью).</li>
        <li>Игрок ожидает <b>15 минут</b>.</li>
        <li>Игроку восстанавливается все живые хиты.</li>
        <li>Игрок выходит из статуса <b>"Лечение"</b> (снимает повязку).</li>
      </ol>

      <div className={s.label}>Лечение <b>"Целебной мазью"</b></div>
      <div>Персонаж спасается от смерти благодаря алхимической мази.</div>
      <ol className={s.listContainer}>
        <li>Карта <b>"Целебная мазь"</b> разрывается.</li>
        <li>персонаж восстанавливает все живые хиты</li>
      </ol>
      <div><b>ПРИМЕЧАНИЕ:</b> Нельзя использовать во время активной фазы боя и при штурмах.</div>

      <div className={s.label}>Условия срыва лечения</div>
      <div className={s.listContainer}>
        <li><b>Получение урона:</b> Если игрок получил урон, когда прибывал в статусе <b>"Лечение"</b> - то он переходит в статус <b>"Тяжело ранен"</b>.</li>
        <li><b>Загнивание раны:</b>  Игрок значительно удалился от госпиталя при лечении в нем. Игрок переходит в состояние <b>"Тяжело ранен"</b>.</li>
      </div>
      <div><b>ПРИМЕЧАНИЕ:</b> После срыва лечения ресурсы и время затраченное на него не восстанавливаются</div>
    </>
}