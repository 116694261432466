import { Item } from "../type";

import s from './actions.module.css';

export const ham: Item = {
  label: 'Чудовищный плен',
  element:
    <>
      <div>Чудовище запасается свежим мясом у себя в логове с целью вылечиться в будущем.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Любой игрок попавший в логово в состоянии <b>"Тяжело ранен"</b> или в плену переходит в состояние <b>"Мертв"</b> (временная смерть).</li>
        <li>Чудовище получает карту <b>"Череп"</b>, которую можно использовать как мгновенное восстановление хитов в пределах логова.</li>
        <li>Пленники чудовища никогда не выживают и не имеют возможности бежать.</li>
        <li>Если у игрока, которого сожрали была карта <b>"Метка жертвы"</b>, то чудовище выдает карту <b>"Сожран"</b> и монстр получает <b>2</b> карты <b>"Череп"</b>.</li>
      </ol>
      <div>Дополнительно: Пленники чудовища никогда не выживают и не имеют возможности бежать. Можно использовать на пленнике <b>«Убить персонажа»</b>.</div>
    </>
}