import { Item } from "../../../../rules-text/type";
import { AccordionBlock } from "../../../accordion/accordion-block";

import s from './text.module.css';

export const stuff: Item = {
  label: 'Вещи',
  element:
    <>
      <div className={s.container}>
        <div>на активной игре всегда что-то может сломаться или порваться, всегда имейте под рукой небольшой комплект для быстрого ремонта</div>
      </div>

      <AccordionBlock
        label="Отправка ТК"
        items={[
          {
            label: 'Логистика',
            element:
              <>
                <div>Убедитесь что ваши вещи есть кому встречать</div>
                <div>Просчитайте логистика так чтоб  на полигоне вещи оказались во время</div>
              </>
          },
          {
            label: 'Перед отправкой',
            element:
              <>
                <div>Осмотрите все свое снаряжение перед выездом</div>
                <div>Примерьте и подгоните его, не откладывайте ремонт на "починю на полигоне"</div>
                <div>Упаковывайте так словно ваше снаряжение будут пытаться уничтожить в ходе доставки, не стесняйтесь уплотнять посылку</div>
              </>
          },
          {
            label: 'После получения',
            element:
              <>
                <div>Не стоит сразу же бросаться яи проверять свое снаряжение, достаточно осмотреть внешне что вы ничего не забыли</div>
                <div>Дальше работайте с командой</div>
                <div>Когда работы над лагерем будут закончены - переодевайтесь и проверяйте снаряжение тщательнее</div>
              </>
          },
        ]}
      />

      <AccordionBlock
        label="Рекомендации"
        items={[
          {
            label: 'Фото',
            element:
              <>
                <div>Когда убедитесь что ваш комплект готов то, сфотографируйте себя в нем с разных сторон</div>
                <div>При формировании посылки это поможет вам вспомнить о всех вещах необходимых вашему образу и ничего не забыть</div>
              </>
          },
          {
            label: 'Списки',
            element:
              <>
                <div>Не стесняйтесь составлять списки</div>
                <div>При этом лучше всего использовать бумагу, так как мобильник может разрядится, бумага вряд ли это сделает</div>
                <div>Не стесняйтесь спрашивать рекомендации у других членов команды</div>
              </>
          },
          {
            label: 'Как мыслить',
            element:
              <>
                <div>Что б окончательно убедится что все в порядке и вы ничего не забыли, постарайтесь представить что вы уже на полигоне и тогда вы поймете что вам нужно будет</div>
              </>
          },
        ]}
      />
    </>
}