import { Chapter } from "../../../components";

import city from '../../../assets/solem/city.jpg';
import mine from '../../../assets/solem/mine.jpg';
import forest from '../../../assets/solem/forest.jpg';
import burn from '../../../assets/solem/burn.jpg';

import s from './solem.module.css';

export function Solem(): JSX.Element {
  return (
    <div className={s.container}>
      <Chapter chapter='ГОРОД СОЛЕМ' />
      
      <div className={s.text}>
        <div className={s.block}>
          <div>
            <img src={city} alt='' />
          </div>

          <div>
            Город Солем, что был основан около сотни лет назад, 
            удачно расположился в предгорной местности в паре дней пути к юго-востоку от границы Империи Тюдор и Королевства Мидленд. 
            Он является довольно важной стратегической точкой. Причин тому несколько, а именно:
          </div>
        </div>

        <div className={s.block2}>
          <div>
            <img src={mine} alt='' />
          </div>

          <ol className={s.listContainer}>
            <li>В горах поблизости располагаются довольно богатые залежи железной руды.</li>
            <li>Близость реки упрощает обработку добытых ресурсов.</li>
            <li>В двух днях пути расположен крупный речной порт, через который удобно доставлять обработанную руду и металлические изделия вглубь Королевства.</li>
            <li>За счёт окружения горами с юга и востока и лесом с запада - город имеет всего одну подходящую дорогу, проходящую через реку с мостом, что упрощает оборону.</li>
          </ol>
        </div>
        <br />
        <div className={s.block2}>
          <div className={s.listContainer}>
            Местные жители и охотники любят судачить о том, что расположенный рядом лес - заколдованный и что там живут разные духи и демоны. 
            В действительности же, в глубине местных лесов расположена древняя аномалия, которая истончает слой между материальным и астральным мирами в этом месте. Эта аномалия провоцирует бурный рост всей живности в лесу поблизости, причём это касается как флоры, так и фауны. 
            В сердце леса невозможно нормально пройти, лесные пожары там не распространяются, а звериные тропы зарастают буквально за сутки. Зато местным охотникам всегда легко изловить дичь даже на самых окраинах леса.
          </div>

          <div>
            <img src={forest} alt='' />
          </div>
        </div>
        <br />
        <div className={s.block}>
          <div>
            Для увеличения кузнечных мощностей города, а также для постройки более крупного моста, выше по течению реки Лозэн была построена дамба. 
            Однако, за несколько дней до начала событий игры дамба была уничтожена, а высвободившийся мощный поток воды уничтожил единственный мост, соединявший Солем с Королевством Мидленд. Однако местные говорят, что в ту ночь видели необычайное красное зарево, обагрившее ночное небо и луну.
          </div>

          <div>
            <img src={burn} alt='' />
          </div>
        </div>
      </div>
    </div>
  )
}