import { Item } from "../type";

import s from './actions.module.css';

export const assault: Item = {
  label: 'Штурм лагеря/города',
  element:
    <>
      <div className={s.label}><i>Требования к лагерю</i></div>
      <ol className={s.listContainer}>
        <li>Наличие расчищенной площадки внутри лагеря.</li>
        <li>Если площадки внутри лагеря нет - оборона должна защищаться вне лагерных укреплений.</li>
      </ol>

      <div className={s.label}><i>Условия проведения</i></div>
      <ol className={s.listContainer}>      
        <li>Имеется командующий штурмом.</li>
        <li>В лагере должен находится интендант, без интенданта штурм лагеря строжайше запрещен.</li>
      </ol>

      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Атакующая сторона выстраивается возле указанного места для штурма лагеря.</li>
        <li>Атакующие объявляют штурм.</li>
        <li>Интендант лагеря встречает штурмующих.</li>
        <li>В течении <b>15 минут</b> (время засекается интендантом лагеря) атакующая и обороняющаяся сторона не уязвима, время дается для общей организации боевой сцены.</li>
        <li>Все обороняющиеся персонажи должны построится на расчищенной площадке внутри расчищенной зоны.</li>
        <li>Присоединятся к штурму после его начала категорически запрещено.</li>
        <li>Стрелять или наносить удары из неподготовленных к штурму зон запрещено.</li>
        <li>Намеренно ломать реквизит запрещено.</li>
      </ol>
      <div className={s.label}><i>Победа</i></div>
        <div>Победа в штурме присуждается той стороне, которая уверено удерживает область входа в лагерь (5 метров перед "воротами" и 5 метров за ними) в течении <b>10 минут</b>.</div>
    </>
}