import { Item } from "../type";

import s from './actions.module.css';

export const craft: Item = {
  label: 'Производство',
  element:
    <>
      <div>Ремесленник использует ресурсы для производства <b>"Медкомплекта"</b>, <b>"Ремокомплекта"</b>, <b>"Целебной мази"</b> или <b>"Оружейного масла"</b>.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Ремесленник посещает мастерского торговца или интенданта.</li>
        <li>Мастерский торговец или интендант выдает реквизит для физического труда.</li>
        <li>Игрок использует первичный ресурс для производства.</li>
        <li>В локации происходит физический труд.</li>
        <li>Мастерский торговец или интендант оценивают качество физического труда и уровень качества локации.</li>
        <li>мастерский торговец или интендант выдает готовую продукцию, которую произвела локация.</li>
      </ol>

      {/* <div className={s.label}><i>Физический труд</i></div>
      <div>Чем выше качество исполнения тем больше товаров получится произвести.</div>
      <div className={s.listContainer}>
        <li><b>Госпиталь</b> необходимо вышить на куске ткани указанный рисунок.</li>
        <li><b>Кузница</b> из проволоки необходимо сплести указанную фигуру.</li>
        <li><b>Алхимия</b> используя набор красителей необходимо получить указанный цвет в пробирке.</li>
      </div> */}
    </>
}