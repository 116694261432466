import { Item } from "../type";

import s from './actions.module.css';

export const changeForm: Item = {
  label: 'Смена формы',
  element:
    <>
      <div>Человеческое тело апостола изрыгает из себя истинный лик чудовища.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Игрок одевает повязку <b>"Мертв"</b>.</li>
        <li>Кратчайшим путем следует в свое логово, где переодевается в монструозную форму (в этот момент чудовище неуязвимо).</li>
        <li>В течении минимум <b>2 часов</b> не имеет права принимать форму человека.</li>
        <li>Если игрок погибает в монструозной форме - мертвяк составляет <b>3 часа</b>.</li>
      </ol>
      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>Игрок-чудовище обязан принять монструозную форму если его человеческая форма получила <b>"Тяжелое ранение"</b>.</li>
        <li>Добивание чудовища в человеческой форме в <b>"Тяжелом ранении"</b> не имеют эффекта.</li>
        <li>В случае плена чудовище в человеческой форме может сбросить с себя кандалы, если вздумает перейти в монструозную форму.</li>
        <li>Время пребывания в мертвяке можно сократить за счет карт <b>"Череп"</b> (1 череп = 30 минут).</li>
        <li>Хиты человеческой формы начисляются по общим правилам, исходя из текущего снаряжения.</li>
      </ol>
    </>
}