import { Item } from "../type";

import s from './actions.module.css';

export const eat: Item = {
  label: 'Пожирание',
  element:
    <>
      <div>Чудовище в монструозной форме поедает свою жертву.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Вне боевой ситуации чудовище прислоняет к игроку-жертве пасть.</li>
        <li>Чудовище в течении <b>15 минут</b> находится возле жертвы, отыгрывая его пожирание. Если на персонаже была <b>"Метка жертвы"</b> тратить время на пожирание не обязательно.</li>
        <li>Игрок-жертва переходит в состояние <b>"Мертв"</b> (временная смерть).</li>
        <li>Игрок-жертва кладет предметы из ДК на землю возле себя.</li>
        <li>Чудовище забирает у игрока карту <b>"Метка жертвы"</b> и отдает карту <b>"Сожран"</b>.</li>
        <li>Если карты «Метка жертвы» не оказалось, то чудовище ничего не передает.</li>
        <li>Чудовище восстанавливает <b>5 хитов</b>.</li>
        <li>сли на игроке была метка жертвы, то чудовище восстанавливает <b>5 хитов</b> мгновенно</li>
      </ol>
      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>Пожирание имеет свойство <b>"Быстрого обыска"</b>, но предметы кладутся на землю.</li>
        <li>Съеденный персонаж выходит из мертвяка без <b>"Метки жертвы"</b> и психозов.</li>
        <li>Если у игрока не было <b>"Метки жертвы"</b> он посещает мертвяк по общим правилам.</li>
        <li>Пожирание одержимых не восстанавливает хиты чудовища.</li>
        <li>Если чудовище атаковали во время пожирания, то хиты не восстанавливаются, а труп, который поедался, считается испорченным.</li>
      </ol>


    </>
}