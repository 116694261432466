import { Item } from "../type";

import s from './actions.module.css';

export const removeAltar: Item = {
  label: 'Осквернение алтаря',
  element:
    <>
      <div>Чудовище уничтожает духовную силу беззащитного алтаря.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Чудовище накрывает алтарь фиолетовой тканью и в течении <b>15 минут</b> взывает к темным силам.</li>
        <li>По окончанию ритуала чудовище имеет право аккуратно забрать алтарь, обернутый в фиолетовую ткань.</li>
        <li>С этого момента считается что чудовище "исчезло вместе с алтарем". Физически чудовище в статусе <b>"Мертв"</b> (одевает белую повязку с черной печатью) перемещает алтарь ближайшему мастеру или интенданту.</li>
        <li>Чудовище в статусе <b>"Мертв"</b> возвращается в логово и может продолжить играть без штрафов.</li>
      </ol>
      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>Для осквернения неважно в какой форме находится чудовище.</li>
        <li>Если чудовище осквернило алтарь в человеческой форме, то после осквернения, этот игрок должен вернутся в логово в состоянии <b>"Мертв"</b>, но вправе выйти из него и продолжить играть в той же человеческой форме.</li>
      </ol>
    </>
}