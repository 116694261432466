import { Item } from "../type";

import s from './actions.module.css';

export const rest: Item = {
  label: 'Отдых',
  element:
    <>
      <div>Карта <b>"Отдых"</b> убирает действие одного из психозов. Получить ее можно несколькими способами:</div>
      <h1 className={s.label}>Отдых в заведении</h1>
      <div>Персонаж может посетить увеселительное заведение, с целью снять стресс и немного расслабиться.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Игрок посещает локацию, где есть возможность отдохнуть (локация помечается специальным маркером на вывеске).</li>
        <li>После обсуждения цены и спектра предоставляемых услуг, игрок сдает свое ДК хозяину заведения.</li>
        <li>Игрок отдыхает в заведении минимум <b>15 минут</b>.</li>
        <li>Хозяин выдает игроку карту <b>"Отдых"</b>.</li>
      </ol>

      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>В случае если в течении <b>15 минут</b> с хозяином или с игроком произойдут боевые взаимодействия - эффект снятия стресса аннулируется (карта «Отдых» не выдается).</li>
        <li>Еще более хорошим тоном будет сдача оружия и снятие шлема.</li>
        <li>Для хозяев заведения было бы хорошим тоном иметь места где сданные вещи могли бы безопасно находится.</li>
      </ol>

      <h1 className={s.label}>Автограф артиста</h1>
      <div>Артист может подарить своему поклоннику автограф, восстановив (или нет) психологическое здоровье персонажа.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Игрок просит артиста поставить ему автограф.</li>
        <li>Артист предлагает вытянуть карту из своей колоды артиста.</li>
        <li>Действие карты вступает в силу немедля.</li>
        <li>Выбранная карта кладется персонажу просителю в ДК.</li>
      </ol>

      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>Артист может сам себе восстанавливать рассудок, вытягивая из своей колоды артиста нужные карты.</li>
        <li>Выбранные карты кладутся персонажу в ДК, покидая колоду артиста.</li>
        <li>Артист в праве просить любые условия для предоставления своего автографа.</li>
      </ol>

      <h1 className={s.label}>Исповедь</h1>
      <div>Инквизитор исповедует персонажа, снимая с его души груз тяжких грехов.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Игрок просит исповедаться у инквизитора.</li>
        <li>Игрок исповедуется у инквизитора минимум <b>15 минут</b>.</li>
        <li>Если инквизитор готов даровать отпущение грехов, он отдает карточку <b>"Отдых"</b> игроку.</li>
      </ol>

      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>Исповедь можно заменить различным видом отыгрыша на усмотрение инквизитора, например, показательная порка, изгнание дьявола, пытки злых духов, испытание веры, чтение молитв и т.д.</li>
        <li>Запрещено наносить реальные увечья или причинять боль.</li>
        <li>Инквизитор вправе сократить или увеличить общее время исповеди, отталкиваясь от качества отыгрыша.</li>
      </ol>
    </>
}