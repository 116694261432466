import { Item } from "../type";

import s from './actions.module.css';

export const performance: Item = {
  label: 'Выступление',
  element:
    <>
      <div>Артист может публично выступить в центральном кабаке или на площади, или в лагере в присутствии интенданта.</div>
      <div>Качество его выступления оценивается громкостью аплодисментов или иным очевидным.</div>
      <br />
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Артист выступает на публике.</li>
        <li>Староста деревни, трактирщик или интендант оценивает качество выступления.</li>
        <li>Выдается некоторое количество карт в колоду артиста.</li>
      </ol>
      <div>ПРИМЕЧАНИЕ: Публичные выступление – единственный способ пополнить колоду артиста.</div>
    </>
}