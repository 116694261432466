import { Item } from "../../../../rules-text/type";
import { AccordionBlock } from "../../../accordion/accordion-block";

import s from './text.module.css';

export const rate: Item = {
  label: 'Расходники',
  element:
    <>
      <div className={s.container}>
        <div>на активной игре всегда что-то может сломаться или порваться, всегда имейте под рукой небольшой комплект для быстрого ремонта</div>
      </div>

      <AccordionBlock
        label="Снаряжение"
        items={[
          {
            label: 'Оружие',
            element:
              <>
                <div>Если оружие сломалось или вы видите что вот-вот сломается, то не стоит даже начинать его чинить - это может повлечь за собой травмы игроков, лучше отложите его в сторону и потратьте эти силы на поиск нового оружия</div>
              </>
          },
          {
            label: 'Одежда',
            element:
              <>
                <div>Возьмите с собой нитку и иголки, бывает такое что что-то порвется и это надо срочно сшить, чаще всего это можно сделать самому на месте</div>
              </>
          },
          {
            label: 'Броня',
            element:
              <>
                <div>Возьмите с собой несколько гаек под ваши клепки для срочной замены + пара палок термоклея, который можно плавить зажигалкой и просто клей 88</div>
                <div>Если что-то надо стянуть не используйте проволку так как она может портить и оружие и нанести травмы - используйте стяжки, пусть смотрится менее хорошо, но быстро и зачастую надежно</div>
                <div>очень выручает веревка, так что возьмите с собой пеньковую веревку диаметром от 6мм хотя бы пару метров</div>
              </>
          },
          {
            label: 'Строяк',
            element:
              <>
                <div>Все расходники на строяк лучше комплектовать не с личными вещами а со строяком и держать не далеко от основных вещей лагеря, так вы не запутаетесь и будете знать где искать</div>
              </>
          },
        ]}
      />
    </>
}