import { Item } from "../type";

import s from './actions.module.css';

export const exile: Item = {
  label: 'Изгнание злого духа',
  element:
    <>
      <div>Изгнать дух из последнего идола невозможно, но это можно будет сделать в рамках сюжета.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Инквизитор накрывает еретический алтарь красной тканью.</li>
        <li>В течении <b>15 минут</b> инквизитор читает молитвы и всячески борется с злым духом внутри идола.</li>
        <li>Инквизитор переходит в состояние <b>"Тяжело ранен"</b>.</li>
        <li>Инквизитор может переместить данный алтарь, завернутый в красную ткань - мастерам.</li>
      </ol>

      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>В случае, если инквизитор или его свита не смогли донести идол до мастеров, то он остается на том месте где его положили и продолжает принимать жертвы - ритуал считается сорванным.</li>
        <li>В случае смерти инквизитора во время ритуала, алтарь могут донести за него другие представители инквизиции - ритуал будет считаться завершенным.</li>
      </ol>
    </>
}