import { Item } from '../type';

import s from './battle.module.css';

export const preparing: Item = {
  label: 'Подготовка',
  marker: 'Белый дым и звуковой сигнал',
  element:
    <ol className={s.listContainer}>
      <li>В этой фазе все не участвующие в битве игроки приглашаются в зрительскую зону, а все участвующие в битве приглашаются в боевую зону для ожидания дальнейших указаний своих капитанов и интендантов.</li>
      <li>Все игровые процессы останавливаются и фиксируются мастерами. Заявки больше не принимаются.</li>
      <li>Производится подсчет всех карт <b>"Сожран"</b> и <b>"Прощение"</b>, которые смогли реализовать чудовища и инквизиция в течении игры.</li>
    </ol>
}