import { Item } from "../type";

import s from './actions.module.css';

export const ancestor: Item = {
  label: 'Оформить наследство',
  element:
    <>
      <div>Персонаж может оформить «Наследную грамоту» у инквизитора, которая обладает следующими свойствами:</div>
      <ol className={s.listContainer}>
        <li> Грамота неотчуждаемая.</li>
        <li>Грамоту можно добровольно передать другому игроку.</li>
        <li>Можно расплачиваться грамотой с мастерским торговцем.</li>
        <li>После смерти обналичивается в качестве подъёмных нового персонажа.</li>
        <li>Наследную грамоту можно купить у инквизитора за указанный номинал.</li>
        <li>Существует всего два вида грамот: золотая и серебряная грамота.</li>
        <li>В случае временной смерти может не обналичиваться после мертвяка.</li>
        <li>В случае смерти персонажа грамоту обязательно надо обналичить у мастера или интенданта.</li>
      </ol>
    </>
}