import { Item } from "../type";

import s from './actions.module.css';

export const addAltar: Item = {
  label: 'Установить алтарь',
  element:
    <>
      <div>Инквизитор устанавливает алтарь для подношений в локации.</div>
      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Инквизитор передает через мастера деньги в фонд Святого престола.</li>
        <li>Мастер выдает инквизитору церковный алтарь, обернутый в красную ткань.</li>
        <li>Взяв этот алтарь, инквизитор может установить его куда угодно по своему усмотрению.</li>
        <li>Фактом установки является убранная красная ткань.</li>
      </ol>

      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>Устанавливать алтарь имеет возможность только инквизитор.</li>
        <li>Создать более определенного лимита алтарей невозможно (определяется мастерами).</li>
        <li>Перемещать алтарь могут только чудовища в рамках ритуала <b>"Осквернение алтаря"</b>.</li>
      </ol>
    </>
}