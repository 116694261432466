import { Item } from "../rules-text/type";

import baldenCoat from '../assets/coats/balden.png'; 

import s from './history.module.css';

export const baldenHistory: Item = {
  label: 'Королевство Балден',
  element:
    <>
      <div className={s.info}>
        <div className={s.coat}>
          <img src={baldenCoat} alt='' />
        </div>

        <div className={s.text}>
          <div><i>Правитель: </i>королева Джоана</div>
          <div><i>Столица: </i>город Ригленто</div>
          <div><i>Герб: </i>Красная львица на бело-зеленом фоне</div>
          <div><i>Цвета: </i>Зеленый и белый - для подданных. Королевские цвета - красный и золотой</div>
        </div>
      </div>

    <div className={s.block2}>
      <h3>Краткая история</h3>
      <div>На текущий момент королевством правит королева-регент, хоть и номинально наследником является трехлетний Фалко</div>
      <br/>
      <div>Королевство Балден является активным союзником империи Тюдор</div>
      <div>Но основным пунктом производства считается продовольствие</div>
      <div>Королевства Балден активно использует каперов в скрытой борьбе с Альянсом Панерия, который берет пошлину за пересечение морских границ, усложняя для королевства торговлю с восточными регионами континента</div>
      <div>Флот королевства является сдерживающей силой мощной армады княжества Ис, которое постоянно устраивает набеги на западное побережье континента</div>
      <div>При этом часть флота королевства под пиратскими флагами сдерживает торговлю альянса Панерии с северо-западными регионами континента</div>
    </div>
  </>
}