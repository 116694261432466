import { Item } from "../type";

import s from './actions.module.css';

export const killPerson: Item = {
  label: 'Убить персонажа',
  element:
    <>
      <h2 className={s.label}><i>Временная смерть</i></h2>
      <div>Если после смерти персонажа никто не заявлял об убийстве и не была сделана соответствующая пометка в ДК, то эта смерть считается временной.</div>
      <div className={s.label}>Следствия временной смерти:</div>
      <ol className={s.listContainer}>
        <li>Игрок сидит полный цикл мертвяка.</li>
        <li>Кидает кубик на психоз.</li>
        <li>Использует или не использует действие <b>«Темная сделка»</b>.</li>
        <li>Игрок не помнит последних событий жизни своего персонажа, приведших к его гибели.</li>
      </ol>

      <h2 className={s.label}><i>Смерть персонажа</i></h2>
      <div className={s.label}>Механика:</div>
      <ol className={s.listContainer}>
        <li>Убийца просит жертву показать свой ДК.</li>
        <li>Своими руками или руками жертвы в ДК ставится пометка о смерти персонажа.</li>
        <li>Игрок, чей персонаж умер, приходит в мертвяк и сдает свой ДК.</li>
        <li>После отсидки мертвяка игрок выходит новым персонажем.</li>
        <li>При этом все эффекты, которые действовали на старого персонажа пропадают.</li>
      </ol>
    </>
}